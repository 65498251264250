import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// home
import HomePage from './home/HomePage';

// company
// import AboutPage from './company/about/AboutPage';
// import TeamPage from './company/team/TeamPage';
// import ContactPage from './company/contact/ContactPage';

// legal
// import TermsOfServicePage from './legal/TermsOfServicePage';
// import MobileTermsOfServicePage from './legal/MobileTermsOfServicePage';
// import PrivacyPolicyPage from './legal/PrivacyPolicyPage';
// import ProductPage from './products/ProductPage';

const MainRoutes = () => {
  return (
    <Routes>
      {/* home */}
      <Route path="/" element={<HomePage />} />

      {/* company */}
      <Route path="/about" element={<HomePage />} />
      <Route path="/team" element={<HomePage />} />
      <Route path="/contact" element={<HomePage />} />
      
      {/* legal */}
      <Route path="/tos" element={<HomePage />} />
      <Route path="/mobiletos" element={<HomePage />} />
      <Route path="/privacy" element={<HomePage />} />

      {/* Products */}
      <Route path="/products" element={<HomePage />} />

      {/* 404 */}
      <Route path="*" element={<Navigate to="/" replace />} />
      
    </Routes>
  );
};

export default MainRoutes;
