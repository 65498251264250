import React from 'react';
import Navbar from '../layout/Navbar';
import FullScreenSection from './FullScreenSection';


function HomePage() {
  return (
    <div className='HomePage'>
      <Navbar />
      <FullScreenSection videoBackground="/TranscendWebsiteMP4.mp4">
        <div className="text-center p-4">
          <h1 className="text-4xl text-white font-bold font-monocraft mb-4 text-shadow-md text-shadow">
            Building the next<br />generation of CVTs
          </h1>
          <a href="https://forms.gle/aWNrGWM1qAoaMfocA" className="inline-block">
            <p className="text-xl text-white font-monocraft text-shadow-md border-2 border-white p-2">
              Sign up for our newsletter
            </p>
          </a>
        </div>
      </FullScreenSection>
      {/* <LearnMoreSection /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default HomePage;
