import React from 'react';

interface FullScreenSectionProps {
  videoBackground: string;
  children?: React.ReactNode;
}

const FullScreenSection: React.FC<FullScreenSectionProps> = ({ videoBackground, children }) => {
  return (
    <div className="h-screen w-full overflow-hidden relative">
      <video 
        autoPlay 
        loop 
        muted 
        playsInline 
        className="absolute w-auto min-w-full min-h-full max-w-none"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: -1 }}
      >
        <source src={videoBackground} type="video/mp4" />
      </video>
      {/* Overlay Content Container */}
      <div className="absolute inset-0 flex items-center justify-center z-10">
        {/* Centered Content */}
        <div className="text-center p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FullScreenSection;