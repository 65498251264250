import React, { useState, useEffect } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsScrolled(offset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='fixed top-0 left-0 right-0 z-20'>
      <nav className={`p-4 flex items-center ${isScrolled || isOpen ? 'bg-backgroundblack' : 'bg-transparent'} text-white justify-between navbar-transition`}>
        <div className="flex-grow basis-0">
          <button onClick={toggleMenu} className="text-xl lg:hidden">
            {isOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>
        <div className="flex-grow flex justify-center">
          <a href="/" className="flex justify-center">
            <img src="/logowhite.png" alt="Logo" className="h-8" />
          </a>
        </div>
        <div className="flex-grow basis-0"></div>
      </nav>
    </div>
  );
};

export default Navbar;